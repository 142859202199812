.header .menu {
    font-size: 0.75rem;
}


.header .language {
    margin-top: 10px;
}

.header-texts h1{
    margin-top: 100px;
    margin-bottom: 50px;
    max-width: 700px;
}

.header-texts {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 1rem;
    color: #005679;
    margin-top: 50px;
    margin-bottom: 30px;
}

.header-item p {
    color: #252529;
}

.header-down {
    margin: 120px 0;
}

.header-item-image {
    height: 100px;
}

.header-items {
    margin-top: 100px;
    box-shadow: 0px 25px 25px #5761A622;
}

.header-items .col {
    height: 225px;
}

.header .background-img {
    width: 100%;
    text-align: right;
}

.header .background-img img {
    margin-right: -350px;
    margin-top: -40px;
}

.header a, .header a:visited {
    color: #005679;
}

.header a:hover {
    color: #067aa8;
}

.header button {
    border: none;
    background-color: #3FB0F7;
    padding: 20px 40px;
    font-size: 1rem;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 600;
    box-shadow: 0px 30px 30px #3FB0F766;
}


@media (max-width: 992px) {
    .header .background-img img {
        margin-right: -450px;
    }
}


@media (max-width: 767.98px) {
    .header-texts h1{
        margin-top: 0;
        margin-bottom: 40px;
    }

    .header-texts p{
        max-width: 200px;
    }
    
    .header .background-img img {
        margin-right: -150px;
        margin-top:30px;
        width: 90%;
    }

    .header-texts {
        font-size: 0.75rem;
    }

    .header button {
        width: 100%;
        font-size: 0.75rem;
        margin-top:150px;
    }

    .header-down {
        margin: 130px 0;
    }

    .header-menu-image img{
        margin: 15px;
    }
}

@media (max-width: 575.98px) {
    .header .background-img img {
        margin-right: -150px;
        margin-top:130px;
        width: 90%;
    }

    .header button {
        margin-top:50px;
    }

    .header-down {
        margin: 100px 0;
    }

    .header-items p {
        font-size: 0.75rem;
        max-width: 130px;
    }
}