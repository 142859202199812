.contact-list-content {
    display: flex;
}

.contact-list .row {
    height: 50px;
    margin-bottom: 30px;
}



.contact-list p {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 10px;
    padding-right: 0;
    margin: 0px 0;
}

.contact-list img {
    margin: 0 10px;
}

.contact-list-content {
    max-width: 400px;
}

.contact-list-address img {
    margin: 10px;
}