.form-popup h1 {
    margin: 20px;
}

.form-popup {
    width: 100vw;
    height: 100vh;
    background-color: #FFFFFF;
    overflow: scroll;
}

.form-popup .form-popup-container{
    width: 600px;
    text-align: center;
    background-color: white;
    padding: 30px 0;
}

.form-popup .form-popup-container .col {
    padding: 30px;
}

.form-popup p {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 0.75rem;
    color: #252529;
}

.form-popup-close {
    position:absolute;
    width: 600px;
    text-align: right;
}

.form-popup-close img{
    margin-right: 30px;
    cursor: pointer;
}

@media (max-width: 767.98px) {
    .form-popup .form-popup-container{
        width: 100vw;
    }

    .form-popup {
        background-color: white;
    }

    .form-popup-close {
        visibility: hidden;
    }
}