.footer {
    background-color: #005679;
    padding: 50px 50px 10px 50px;
    margin-top: 120px;
}

.footer a, .footer a:visited {
    color: #ffffff;
}

.footer a:hover {
    text-decoration: underline;
}

.footer .logo-text p {
    color: white;
}

.footer .contact-list {
    color: white;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 0.75rem;
    margin: 10px 0;
}

.footer .copyright, .footer .terms {
    text-align: center;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 0.6rem;
    color: #F2F2F2;
    margin: 5px 0;
}

.footer-menu {
    height: 150px;
}

.footer .menu {
    font-size: 0.75rem;
}

@media (max-width: 992px) {
    .footer {
        background-color: #005679;
        padding: 20px 20px 10px 20px;
        margin-top: 120px;
    }
}

@media (max-width: 767.98px) {
    .footer {
        margin-top: 50px;
    }
    .footer .logo{
        margin-top: 30px;
    }

    .footer .contact-list .col{
        padding: 0;
    }

    .footer .contact-list{
        margin-left: 0px;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .footer .contact-list-address {
        height: 60px;
    }

    .footer .contact-list-phone {
        height: 60px;
    }

    .footer .contact-list-email {
        height: 60px;
    }

    .footer .contact-list img {
        margin-right:15px;
    }
}