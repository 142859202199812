.language .col {
    display: flex;
}

.language {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 0.75rem;
    color: #005679;
    height: 30px;
}

.language .selected {
    color: #3FB0F7;
}

.language img {
    vertical-align: top;
    margin: 6px;
}

.language .vertical-line {
    border-left: 1px solid #00567955;
    height: 20px;
    margin: 3px 10px;
}

.language .not-selected {
    cursor: pointer;
}