.logo .column {
    display: table-cell;
}

.logo-text p {
    font-size: 1rem;
    font-family: Helvetica, Arial, sans-serif;
    margin-left: 0.6rem;
    color: #005679;
    
}

.logo-text p {
    line-height: 0;
}

.logo-text {
    padding: 0;
}

.logo-img img{
    margin-top: 19px;
}

@media (max-width: 575.98px) {
    .logo-text p {
        font-size: 1rem;
    }

    .logo-text {
        padding-top: 20px;
    }
    
}

