.screen {
    width: 100vw;
    height:100vh;
    position:relative;
}

.screen-container {
    width: 100vw;
    height:100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    position:absolute;
}

.screen-container-no-scroll {
    width: 100vw;
    height:100vh;
    overflow: hidden;
    position:absolute;
}

.screen-overlay {
    width: 100vw;
    height:100vh;
    position:absolute;
    overflow: scroll;
    z-index: 20;
}