.mobile-menu {
   width: 100vw;
   padding-top: 30px;
   padding-bottom: 30px;
   background-color: #F0F9F8;
}

.mobile-menu .menu a{
   color: #005679;
}

.mobile-menu .menu {
   max-width: 200px;

}


.mobile-menu .mobile-menu-email img {
   margin: 20px 10px;
}


.mobile-menu .mobile-menu-email p {
   padding: 0;
   margin: 20px 10px;
}