.projects-item {
    text-align: center;
    padding: 0;
}
.cellsolar {
    width: 300px;
    margin: 103px 0px;
}

.xenter {
    width: 100px;
    margin: 108px 70px;
}

.sih {
    width: 160px;
    margin: 100px 30px;
}

.botkyrks {
    width: 300px;
    margin: 105px 0px;
}

.projects {
    margin-top: 120px;
    margin-bottom: 100px;
}

.projects img {
    cursor: pointer;
}

@media (max-width: 767.98px) {
    .projects-item {
        text-align: center;
        padding: 0;
        margin: 30px 0;
    }
}