.contact {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 0.75rem;
    color: #252529;
    margin-top: 160px;
}

.contact .contact-list{
    margin-left: 40px;
}

.contact .map {
    width: 100%;
    height: 210px;
}

.contact .map-pointer {
    width:37px;
    height:40px;
    font-size: 12px;
    font-weight: bold;
}

.contact .map-pointer p {
    position: absolute;
    margin-top:-50px;
    margin-left:20px;

}

.contact .map-pointer img {
    position: absolute;
    margin-top:-60px;
    margin-left:-20px;

}

.contact a, .contact a:visited {
    color: #252529;
}

.contact a:hover {
    text-decoration: underline;
}

.contact .logo-text p {
    color: #252529;
}

@media (max-width: 767.98px) {
    .contact .contact-list{
        margin-left: 0px;
        margin-top: 40px;
    }

    .contact .contact-list-address {
        page-break-after: 20px 20px;
    }

    .contact .contact-list .col{
        padding: 0;
    }

    .contact .contact-list img {
        margin-right:20px;
    }
}