.why h2 {
    font-family: Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size: 1rem;
    color: #005679;
    margin: 15px 0;
}

.why p {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 0.75rem;
    color: #252529;
    margin: 10px 0;
}

.why-bullet {
    max-width: 70px;
}

.why-list {
    padding: 70px 50px;
    box-shadow: 0px 25px 25px #5761A622;
}

.why-image-down img {
    width: 100%;
}

.why .background-img {
    width: 100%;
    text-align: left;
}

.why .background-img img {
    margin-left: 0px;
}

@media (max-width: 1200px) {
    .why .background-img img {
        margin-left: -100px;
    }
}

@media (max-width: 767.98px) {
    .why .background-img img {
        width: 500px;
        margin-left: -100px;
    }

    .why-list {
        padding: 20px 0px;
        box-shadow: none;
    }

    .why-list img {
        margin-top: 10px;
    }

    .why h1 {
        margin: 0;
    }

    .why .why-image-down{
        padding-bottom: 100px;
    }

}