.meeting {
    margin-top: 150px;
}

.meeting h1 {
    margin-bottom: 40px;
}

.meeting p {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 0.75rem;
    color: #252529;
    margin-bottom: 50px;
}

.meeting .form-label {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 0.75rem;
    font-weight: bold;
    color: #005679;
    margin: 10px 0;
}


.meeting .form-row .col .form-group {
    padding:0;
}

.meeting-form {
    max-width: 600px;
}

.meeting input,
.meeting textarea {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 0.75rem;
    color: #1e3052;
    margin: 0 0;
    padding: 30px 20px;
    background-color: #E5EEF2;
    border: none;
}

.meeting button {
    border: none;
    background-color: #3FB0F7;
    padding: 20px 100px;
    font-size: 24px;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 600;
    box-shadow: 0px 30px 30px #3FB0F766;
}

.meeting-inputs .col{
    padding-left: 0;
    padding-right: 0;
}

.meeting .background-img {
    width: 100%;
    text-align: right;
    overflow: hidden;
}

.meeting .meeting-image-down {
    margin-top: 70px;
}

.meeting .invalid-feedback {
    margin-top: 2px;
    margin-bottom: -30px;
    font-size: 0.75rem;
    color: #b94861;
}

.form-control.is-invalid{
    background-image: none;
}

@media (max-width: 1100px) {
    .meeting .background-img img {
        margin-right: -200px;
    }
}

@media (max-width: 992px) {
    .meeting .background-img img {
        margin-right: -100px;
    }
    .meeting-form {
        max-width:100%;
    }
}

@media (max-width: 767.98px) {
    .meeting button {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }

    .meeting-form {
        max-width:100%;
    }

    .meeting img {
        width: 100%;
    }

    .meeting {
        margin-top: 100px;
    }
}