.testimonials {
    margin-top: 150px;
}

.testimonials h1 {
    margin-top: 50px;
    margin-bottom: 20px;
}

.testimonials-title {
    font-family: Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size: 1rem;
    color: #005679;
    margin: 10px 0;
}

.testimonials-sub {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 0.7rem;
    color: #3FB0F7;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 45px;
}

.testimonials-text {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 0.75rem;
    color: #252529;
    margin: 10px 0;
}

.testimonials-blok {
    margin: 50px 0px;
}

.testimonials-item {
    padding: 50px 50px;
    margin: 0px 0px;
    box-shadow: 0px 25px 25px #5761A622;
    height: 100%;
}

@media (max-width: 992px) {
    .testimonials-item {
        padding: 40px 30px;
    }

    .testimonials-blok {
        margin: 20px 0px;
    }
}


@media (max-width: 767.98px) {
    .testimonials-title {
        margin-top: 50px;
    }


    .testimonials-sub {
        margin-right: 20px;
    }

    .testimonials h1 {
        margin: 0;
    }

    .testimonials {
        margin-top: 50px;
    }

    .testimonials .testimonials-collapse{
        padding-bottom: 50px;
    }
}
