html {
    font-size: 24px;
}

h1 {
    font-family: Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size: 3rem;
    color: #005679;
    margin-bottom: 60px;
}

@media (max-width: 1400px) {
    .container {
        min-width: 100%;
    }
}

@media (min-width: 1400px) {
    .container, 
    .container-md, 
    .container-sm {
        max-width: 1400px;
    }
}


.main {
    width: 100vw;
}

@media (max-width: 767.98px) {
    h1{
        font-size: 1.2rem;
        margin-top: 0px;
    }
}