.technologies {
    margin-top: 160px;
}
.technologies h2 {
    font-family: Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size: 1rem;
    color: #005679;
    margin-top: 10px;
    margin-bottom: 20px;
    max-width: 150px;
}


.technology-tech {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 0.75rem;
    color: #252529;
    margin: 10px 0;
}

.technology-tech .col {
    padding: 5px 0;
}



@media (max-width: 767.98px) {

    .technologies h2 {
        font-size: 0.75rem;
        margin-top:20px;
    }
    
    .technology-tech {
        margin: 15px 0;
    }
}